@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import "base.css";
  @import "utils.css";
  @import "animations.css";
  @import "podium.css";
  @import "avatar.css";
  @import "emoji-picker.css";
  /* Prevent body scroll when modal is open */
  .Dialog__overlay {
    @apply overflow-hidden;
  }

  /* Ensure modal content is properly contained */
  .Dialog__content {
    @apply overflow-hidden;
  }
}
