/* Default branding colors */
:root {
  --primary-color-50: #e7f4fd;
  --primary-color-100: #d0eafb;
  --primary-color-200: #a0d5f8;
  --primary-color-300: #71c0f4;
  --primary-color-400: #41abf1;
  --primary-color-500: #1086d4;
  --primary-color-600: #0e78be;
  --primary-color-700: #0b5a8e;
  --primary-color-800: #073c5f;
  --primary-color-900: #041e2f;
}

body {
  @apply bg-bgcolor;
}

.cursor-newtab {
  cursor:
    url("/images/new-tab.png") 10 10,
    pointer;
}

/* #region  /**=========== Typography =========== */
.h0 {
  @apply font-primary text-4xl font-semibold md:text-5xl;
}

h1,
.h1 {
  @apply font-primary text-2xl font-semibold text-primary-800 md:text-4xl;
}

h2,
.h2 {
  @apply font-primary text-xl font-medium text-primary-800 md:text-3xl;
}

h3,
.h3 {
  @apply font-primary text-lg text-gray-600 md:text-2xl;
}

h4,
.h4 {
  @apply font-primary text-base font-normal text-gray-600 md:text-lg;
}

body,
.p {
  @apply font-primary text-base font-normal text-textcolor;
}

/* #endregion  /**======== Typography =========== */

.layout {
  /* 1100px */
  max-width: 80rem;
  @apply mx-auto w-11/12;
}

/* Class to adjust with sticky footer */
.min-h-main {
  @apply min-h-[calc(100vh-56px)];
}

.speech-bubble:after {
  --primary-color-content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-bottom: 0;
  margin-left: -8px;
  margin-bottom: -8px;
}

.react-json-viewer-container {
  @apply bg-gray-10 p-4;
}
