.avatar-podium {
  position: relative;
  z-index: 1;
  padding: 4px;
  margin-bottom: 15px;
}

.avatar-podium-inner {
  /* Avatar image */
  position: relative;
  z-index: 3;
}

.avatar-podium::before {
  /* Podium rank border */
  content: "";
  position: absolute;
  z-index: 4;
  left: 50%;
  bottom: -15px;
  width: 30px;
  height: 30px;
  margin-left: -15px;
  border-radius: 100%;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.avatar-podium-rank {
  /* Podium rank */
  position: absolute;
  z-index: 4;
  left: 50%;
  bottom: -11px;
  width: 22px;
  height: 22px;
  margin-left: -11px;
  border-radius: 100%;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 22px;
}

.avatar-podium-gold {
  background-image: linear-gradient(180deg, #ffe297 0%, #e0b62e 100%);
}
.avatar-podium-gold .avatar-podium-rank {
  color: rgba(171, 91, 33, 0.7);
  background-image: linear-gradient(270deg, #ffe297 0%, #e0b62e 100%);
}
.avatar-podium-gold::before {
  background-image: linear-gradient(180deg, #ffe297 0%, #e0b62e 100%);
}

.avatar-podium-silver {
  background-image: linear-gradient(180deg, #f4f4f4 0%, #c0c0c0 100%);
}
.avatar-podium-silver .avatar-podium-rank {
  color: rgba(77, 95, 127, 0.7);
  background-image: linear-gradient(270deg, #f4f4f4 0%, #c0c0c0 100%);
}
.avatar-podium-silver::before {
  background-image: linear-gradient(180deg, #f4f4f4 0%, #c0c0c0 100%);
}

.avatar-podium-bronze {
  background-image: linear-gradient(180deg, #e5b076 0%, #d28a43 100%);
}
.avatar-podium-bronze .avatar-podium-rank {
  color: rgba(171, 91, 33, 0.7);
  background-image: linear-gradient(270deg, #e5b076 0%, #d28a43 100%);
}
.avatar-podium-bronze::before {
  background-image: linear-gradient(180deg, #e5b076 0%, #d28a43 100%);
}
