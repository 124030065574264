.emoji-picker {
  @apply absolute bottom-0 left-0 right-0 m-0 h-[82%] w-full max-w-screen-md overflow-auto rounded-t-xl border
  border-bordercolor bg-white p-6 pb-16 text-xs shadow-2xl transition-all;
  @apply sm:absolute sm:-top-[2px] sm:left-auto sm:right-0 sm:z-10 sm:h-[400px] sm:w-[280px] sm:rounded-lg sm:rounded-tr-none sm:p-0;
}

.emoji-picker-overlay {
  @apply fixed inset-0 z-50 flex size-full items-center justify-center bg-black/40 transition-opacity;
  @apply sm:relative sm:inset-auto sm:z-10 sm:size-auto sm:bg-transparent;
}

.emoji-picker .EmojiPickerReact {
  --epr-bg-color: transparent;
  --epr-text-color: #4d5f7f;
  --epr-highlight-color: #1086d4;
  --epr-hover-bg-color: #dfe4ec;
  --epr-picker-border-color: transparent;
  --epr-picker-border-radius: 0;
  --epr-horizontal-padding: 8px;
  /* Header */
  --epr-header-padding: 8px;
  /*  Emoji */
  --epr-emoji-size: 20px;
  /* Search */
  --epr-search-input-bg-color: #f4f7fb;
  --epr-search-input-height: 30px;
  /* Category Navigation */
  --epr-category-navigation-button-size: 24px;
  /* Category Label */
  --epr-category-label-height: 26px;
  --epr-category-label-padding: 0 12px;
}

.emoji-picker .epr-search-container > input {
  @apply text-sm;
}

.emoji-picker .epr-emoji-category-label {
  @apply text-xs font-semibold text-muted;
}
