.podium {
  width: calc(100% - 8px);
  margin: 32px auto 0 auto;
  height: 100px;
  background: linear-gradient(to bottom, #fff, #f4f7fb);
  box-shadow:
    0 -100px 120px 0 rgba(0, 0, 0, 0.1),
    1px -1px 0 0 rgba(0, 0, 0, 0.05);
}
.podium-lg {
  height: 132px;
}
.podium::after {
  content: "";
  position: absolute;
  top: 0;
  width: calc(100% - 8px);
  margin: auto;
  height: 32px;
  clip-path: polygon(30px 0, calc(100% - 30px) 0, 100% 100%, 0 100%);
  background: linear-gradient(to bottom, #fff, #f4f7fb);
}
